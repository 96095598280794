import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isLoggedInUser } from '@auth/repository/auth.repository';
import { LOGIN_PAGE } from '@shared/constants/ume-constants';

@Component({
  template: '',
})
export class NullComponent implements OnInit {
  private router: Router = inject(Router);

  ngOnInit(): void {
    if (!isLoggedInUser()) {
      this.router.navigate(['/', LOGIN_PAGE]).then();
    }
  }
}
